import Button from "@mui/material/Button/Button";
import Grid from "@mui/material/Grid/Grid";
import Paper from "@mui/material/Paper/Paper";
import TextField from "@mui/material/TextField/TextField";
import { useState } from "react";
import BreadCrumb from "../components/BreadCrumb";
import ChatSystem from "../components/ChatSystem";
import PageTitle from "../resusables/PageTitle";
import SideNav from "./SideNav";

function Chat() {
    const sendMessage = () => {
        console.log(persona, prompt, gender)
    }
    const [persona, setPersona] = useState("");
    const [prompt, setPrompt] = useState("");
    const [gender, setGender] = useState("");
    return (

        <Grid container spacing={1}>
            <Grid item xs={2}>
                <SideNav />
            </Grid>
            <Grid item xs={9} sx={{ height: '100%' }}>
                <div className="container flex flex-col h-screen max-h-screen">
                    <BreadCrumb />
                    <PageTitle title="Speak with nous hermes" />
                    <Paper className="p-3 flex flex-col ">
                        <div className="m-2">
                            <TextField id="persona" value={persona} onChange={(event) => {
                                setPersona(event.target.value); // Update the value when the text changes
                                }}
                                fullWidth label="Persona" className="p-1" variant="standard" />
                        </div>
                        <div className="m-2">
                            <TextField id="prompt" value={prompt} onChange={(event) => {
                                setPrompt(event.target.value); // Update the value when the text changes
                                }}
                                fullWidth label="Prompt" className="p-1" variant="standard" />
                        </div>
                        <div className="m-2">
                            <TextField id="gender" value={gender} onChange={(event) => {
                                setGender(event.target.value); // Update the value when the text changes
                                }}
                                fullWidth label="Gender" className="p-1" variant="standard" />
                        </div>
                    </Paper>

                    <div>
                        <ChatSystem persona={persona} prompt={prompt} gender={gender} />
                    </div>


                </div>
            </Grid>
        </Grid>

    )
}
export default Chat;