import { Breadcrumbs, Typography } from "@mui/material"
import Link from '@mui/material/Link';
import { useLocation, } from "react-router-dom";

function BreadCrumb() {
  const location = useLocation();
  const breadList = location.pathname.split('/').filter(path => path !== '');

  const basePath = window.location.href.split('/home')[0];
  let breadUrl = basePath;
  return (
    <Breadcrumbs className="my-3" aria-label="breadcrumb">

      {breadList.map((element, i) => {
        breadUrl = breadUrl + "/" + element;
        if (i === 0) return null;
        if (element === 'users') return null;
        else if (i === breadList.length - 1) {
          return <Typography
            key={i}
            color="text.primary"
          >{element}</Typography>
        }
        return <Link
          key={i}
          underline="hover"
          color="inherit"
          href={breadUrl}
        >
          {element}
        </Link>
      })}
    </Breadcrumbs>
  )
}
export default BreadCrumb
