import { ACTIONS } from "./interfaces";

// DATABASE CRUD ACTION
export function getChat(
  messages: string[],
  prompt: string,
  persona: string,
  gender: string
) {
  return {
    type: ACTIONS.GET_CHAT,
    payload: { messages, prompt, persona, gender },
  };
}

export function setChat(message: string) {
  return {
    type: ACTIONS.SET_CHAT,
    payload: { message },
  };
}
