import Paper from "@mui/material/Paper/Paper";


type Props = {
    message: string,
    isSender: boolean,
}

function SingleMessage({ message, isSender  }: Props) {
    return (
        <div style={{ textAlign: isSender ? 'right' : 'left' }}>
          <Paper elevation={3} style={{ padding: '10px', marginBottom: '10px', maxWidth: '70%' }}>
            {message}
          </Paper>
        </div>
      );
}
export default SingleMessage