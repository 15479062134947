import { getChat, setChat } from "./chatsActions";
import { all, call, put, takeLatest } from "redux-saga/effects";
import axios, { AxiosResponse } from "axios";
import { ACTIONS } from "./interfaces";

const _URL = "https://ytjtrokql0pqb2-4000.proxy.runpod.net";

type GetChatResponse = {
  result: {
    Data: string;
    Persona: string;
    Prompt: string;
  };
  success: boolean;
};

type GetChatAction = ReturnType<typeof getChat>;
function* handleGetChat({ payload }: GetChatAction) {
  try {
    let promptString = "";
    payload.messages.forEach((item) => {
      promptString += "\n ### Assistant: " + item;
    });
    const prompt = promptString + "\n ### Human: " + payload.prompt;
    const persona = payload.persona;
    const gender = payload.gender;
    const body = {
      prompt,
      persona,
      gender,
    };
    const url = _URL + "/api/v1/generate";

    const res: AxiosResponse<GetChatResponse> = yield call(
      axios.post,
      url,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!res) throw new Error("NO RESULT RETURNED FROM API");
    const { result } = res.data;
    yield put(setChat(result.Data));

    // yield put(
    //   setAlert({
    //     msg: 'Projects Fetched Successfully',
    //     config: SUCCESS_SNACKBAR,
    //   })
    // );
  } catch (err: any) {
    // yield put(
    //   setAlert({
    //     msg: err.message,
    //     config: ERR_SNACKBAR,
    //   })
    // );
  }
}
function* interceptGetChat() {
  yield takeLatest([ACTIONS.GET_CHAT], handleGetChat);
}

export function* chatsSagas() {
  yield all([call(interceptGetChat)]);
}
