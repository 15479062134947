import { ReduxAction } from "../rootReducer";
import { ACTIONS, CHATS_INITIAL_STATE, ChatsState } from "./interfaces";

export const chatsReducers = (
  state: ChatsState = CHATS_INITIAL_STATE,
  action: ReduxAction
): ChatsState => {
  switch (action.type) {
    // SAGA ACTIONS ------------------------------------------------------------------------
    case ACTIONS.SET_CHAT:
      return {
        ...state,
        message: action.payload.message,
      };
    default:
      return { ...state };
  }
};
