import { Button } from "@mui/material";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getChat } from "../redux/chats/chatsActions";
import { ReduxAction, RootState } from "../redux/rootReducer";
import SingleMessage from "../resusables/SingleMessage";
type Props = {
  getChat: (messages: string[], prompt: string, persona: string, gender: string) => ReduxAction,
  message: RootState["chats"]["message"]
  gender: string,
  prompt: string,
  persona: string,
}


function ChatSystem({ getChat, message, gender, prompt, persona }: Props) {
  const [messages, setMessages] = useState<string[]>([])
  let isNotAdded = true;
  const sendMessage = () => {
    if (!gender) { alert("gender required"); return; }
    if (!persona) { alert("persona required"); return; }
    if (!prompt) { alert("prompt required"); return; }

    getChat(messages, prompt, persona, gender)
    // if (message)
    //   addMessage(message)
  }

  const addMessage = (message: string) => {
    console.log(message);
    setMessages([...messages, message]);
  };

  useEffect(() => {
    if (message && isNotAdded) {
      isNotAdded = false;
      addMessage(message)
    }

  }, [message])

  return (
    <div>
      <div className="m-2">
        <Button
          variant="outlined"
          color="primary"
          sx={{
            margin: '12px'
          }}
          disableElevation
          onClick={() => sendMessage()}
        >
          Send Message
        </Button>
      </div>

      {messages.map((msg, index) => (
        <SingleMessage key={index} message={msg} isSender={false} />
      ))}
    </div>
    
  );

}


const mapStateToProps = (rootState: RootState) => {
  const { message } = rootState.chats;
  return {
    message
  }
}

const mapDispatch = {
  getChat: (messages: string[], prompt: string, persona: string, gender: string) => getChat(messages, prompt, persona, gender),
}




export default connect(mapStateToProps, mapDispatch)(ChatSystem);
