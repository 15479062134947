type Props = {
    title: string,
}

function PageTitle({ title }: Props){
    return (
        <div>
            <h1 className="mt-6 text-left text-3xl font-bold tracking-tight">{title}</h1> 
            <hr className="mx-12 my-2 border-1"/>
        </div>
               
    );
}
export default PageTitle;