
export const ACTIONS = {
  GET_CHAT: "GET_CHAT",
  SET_CHAT:"SET_CHAT"
};

export const CHATS_INITIAL_STATE: ChatsState = {
  message: ""
};

export interface ChatsState {
  message: string
}

export interface ChatInterface {
  message: string
}
