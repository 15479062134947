import { applyMiddleware, createStore } from "redux";
import logger from "redux-logger";
import { persistReducer } from "redux-persist";
import persistStore from "redux-persist/es/persistStore";
import storage from "redux-persist/lib/storage";
import createSagaMiddleWare from "redux-saga";
import { rootReducer, RootState } from "./rootReducer";
import rootSaga from "./rootSaga";

const sagaMiddlewares = createSagaMiddleWare();
let middlewares: any[] = [sagaMiddlewares];

if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["admin"],
};

// even though we persisted the admin reducer already, we must wrap the whole reducer in persistReducer too
const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);

export const store     = createStore(persistedReducer, applyMiddleware(...middlewares));
export const persistor = persistStore(store);
sagaMiddlewares.run(rootSaga);
